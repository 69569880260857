import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import SoftwareImage from '../images/services/software.jpg'
import InfrastructureImage from '../images/services/infrastructure.jpg'
import CloudImage from '../images/services/cloud.jpg'
import BgImage from '../images/bg/services-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import infrastructure from '../images/services/new/infrastructure.jpg'
import network from '../images/services/new/pragicts-itops-networking.jpg'
import server from '../images/services/new/pragicts-itops-servers.jpg'
import planning from '../images/services/new/pragicts-itops-capacity-plannig.jpg'
import databackup from '../images/services/new/pragicts-itops-backup-and-recovery.jpg'
import patch from '../images/services/new/pragicts-itops-patch-management.jpg'
import support from '../images/services/new/pragicts-itops-it-support.jpg'
import storage from '../images/services/new/pragicts-itops-storage.jpg'
import asset from '../images/services/new/pragicts-itops-it-asset-management.jpg'
import backup from '../images/services/new/backup.jpg'

import virtualization from '../images/services/new/virtualization.jpg'
import cloud from '../images/services/new/cloud.jpg'

import consulting from '../images/services/new/consulting.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'

const HomeStats = () => {

    return (
        <section className="dark-bg no-padding" id="services">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Services </div>
            <div >
                <div className="section-title fl-wrap">
                    <h3>Our Services</h3>
                    <h2>ITOps <span> Services</span></h2>
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '10px', paddingBottom: '10px'}} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><Link to="/services">IT Infrastructure Implementation and Management</Link></h3>
                        <Link to="/services" className="fs-carousel-link">More Details</Link>
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={infrastructure}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Network Implementation and Management</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={network}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Server Implementation and Management</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={server}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Data Storage Implementation and Management</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>04.</span></div>
                    <div className="bg"  data-bg={storage}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Data Backup and Recovery</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>05.</span></div>
                    <div className="bg"  data-bg={databackup}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Patch Management</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>06.</span></div>
                    <div className="bg"  data-bg={patch}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">IT Support (L1, L2, L3)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>07.</span></div>
                    <div className="bg"  data-bg={support}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">User Account Management</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>08.</span></div>
                    <div className="bg"  data-bg={CloudImage}></div>
                    <div className="overlay"></div>
                </div>   <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">IT Asset Management (ITAM)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>09.</span></div>
                    <div className="bg"  data-bg={asset}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Capacity Planning & Future Proofing</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>10.</span></div>
                    <div className="bg"  data-bg={planning}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">IT Security</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>11.</span></div>
                    <div className="bg"  data-bg={networking}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Information Technology Infrastructure Library (ITIL) </a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>12.</span></div>
                    <div className="bg"  data-bg={virtualization}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Security Information and Event Management (SIEM)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>13.</span></div>
                    <div className="bg"  data-bg={cloud}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Identity and Access Management (IAM)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>14.</span></div>
                    <div className="bg"  data-bg={security}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Security Policy Development</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>15.</span></div>
                    <div className="bg"  data-bg={performance}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Penetration Testing</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>16.</span></div>
                    <div className="bg"  data-bg={rationalizaion}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Security orchestration, automation and response (SOAR)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>17.</span></div>
                    <div className="bg"  data-bg={backup}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Endpoint Security</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>18.</span></div>
                    <div className="bg"  data-bg={support}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="https://automation.pragicts.com/">IT Automation</a></h3>
                        <a target="_blank" href="https://automation.pragicts.com/" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>19.</span></div>
                    <div className="bg"  data-bg={consulting}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item new">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Microsoft Systems Center Configuration Manager (MSCCM)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>20.</span></div>
                    <div className="bg"  data-bg={networking}></div>
                    <div className="overlay"></div>
                </div>
         
         
         
         
         
         
         
         
         
         
         
               
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter"></div>
        </div>
        </div>
    </section>

    )
}

export default HomeStats
