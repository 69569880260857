import React from 'react'
import PragICTSAwsUpgradeThumbnail from '../images/projects/pragicts-aws-online-presence-upgrade.jpg'
import AgcEgnyteImplementationThumbnail from '../images/projects/egnyte-agc-implementation.jpg'
import BiophiliaPlantDBThumbnail from '../images/projects/biophilia-knowledgebase.jpg'
import AslAwsmigrationThumbnail from '../images/projects/asl-thumb.jpg'
import ToolshubEcommerceThumbnail from '../images/projects/toolshub-thumb.jpg'
import GreenWings from '../images/projects/greenwin-thumbnail-896e32a0894fe3da52b138b0e40a5dc3.jpeg'
import PandT from '../images/projects/pandt-thumb-9dd8f24ffffd7df379f07f7d6497bff2.jpeg'

import virtues from '../images/projects/virtue-thumbnail-cafab11370fc2fe870c26e55f02019c4.jpeg'
import shanteas from '../images/projects/shan-teas.png'
import Hingurana from '../images/projects/hingurana-group.png'
import  Bluesky from '../images/projects/blue-skye.png'
import Adylet from '../images/projects/adylet (1).png'
import flareup from '../images/projects/flareup.png'
import octagon from '../images/projects/octagon.png'
import unicorn from '../images/projects/unicorn.png'
import zeiss from '../images/projects/zeiss.png'

import { Link } from "gatsby"

const RecentProjects = [
    {
        id: 1,
        company: 'Zeiss Industrial',
        thumbnail: zeiss,
        tags: [
            {
                id: 1,
                title: 'Website Design, Development & Support'
            }
        ]
    },
    {
        id: 2,
        company: 'Unicorn Metalics',
        thumbnail: unicorn,
        tags: [
            {
                id: 2,
                title: 'Website Design, Development & Support'
            }
        ]
    },
    {
        id: 3,
        company: 'Octagon Equipment',
        thumbnail: octagon,
        tags: [
            {
                id: 3,
                title: 'Website Design, Development & Support'
            }
        ]
    },
    {
        id: 4,
        company: 'Adylet',
        thumbnail: Adylet,
        tags: [
            {
                id: 4,
                title: 'Web Application Platform'
            }
        ]
    },
    {
        id: 5,
        company: 'Hingurana Group',
        thumbnail: Hingurana,
        tags: [
            {
                id: 5,
                title: 'Website Development'
            }
        ]
    },
    {
        id: 6,
        company: 'Flare Up Digital',
        thumbnail: flareup,
        tags: [
            {
                id: 6,
                title: 'Website Development'
            }
        ]
    },
    {
        id: 7,
        company: 'Blue Sky ',
        thumbnail: Bluesky,
        tags: [
            {
                id: 7,
                title: 'Website Development'
            }
        ]
    },

    {
        id: 8,
        company: 'Shan teas',
        thumbnail: shanteas,
        tags: [
            {
                id: 8,
                title: 'Website Development'
            }
        ]
    },
    {
        id: 9,
        company: 'P & T',
        thumbnail: PandT,
        tags: [
            {
                id: 9,
                title: 'Website Development'
            }
        ]
    },

    {
        id: 10,
        company: 'Virtue',
        thumbnail: virtues,
        tags: [
            {
                id: 10,
                title: 'Website Development'
            }
        ]
    },
    {
        id: 11,
        company: 'Green Win technologies',
        thumbnail: GreenWings,
        tags: [
            {
                id: 11,
                title: 'Website Development'
            }
        ]
    },
    {
        id: 12,
        company: 'PragICTS',
        thumbnail: PragICTSAwsUpgradeThumbnail,
        tags: [
            {
                id: 12,
                title: 'Cloud Migration'
            }
        ]
    },
    {
        id: 13,
        company: 'AGC',
        thumbnail: AgcEgnyteImplementationThumbnail,
        tags: [
            {
                id: 13,
                title: 'Cloud Services Implementation'
            }
        ]
    },
    {
        id: 14,
        company: 'Biophilia',
        thumbnail: BiophiliaPlantDBThumbnail,
        tags: [
            {
                id: 14,
                title: 'Web Application'
            },
            {
                id: 14,
                title: 'Application Support'
            }
        ]
    },
    {
        id: 15,
        company: 'Authenticities Sri Lanka',
        thumbnail: AslAwsmigrationThumbnail,
        tags: [
            {
                id: 15,
                title: 'Cloud Migration'
            }
        ]
    },
    {
        id: 16,
        company: 'Toolshub',
        thumbnail: ToolshubEcommerceThumbnail,
        tags: [
            {
                id: 16,
                title: 'eCommerce'
            },
            {
                id: 16,
                title: 'SEO'
            },
            {
                id: 16,
                title: 'Application Support'
            },
            {
                id:16,
                title: 'Branding'
            }
        ]
    },
   
]

const HomeRecentProjects = () => {

    return (
        <section className="dark-bg" id="projects">
            <div className="fet_pr-carousel-title">
                <div className="fet_pr-carousel-title-item">
                    <h3>Featured Projects</h3>
                    <p>Few of our project highlights</p>
                    <Link to="/projects" className="btn float-btn flat-btn color-btn mar-top">View Portfolio</Link>
                </div>
            </div>
            <div className="slider-carousel-wrap fl-wrap">
                <div className="fet_pr-carousel cur_carousel-slider-container fl-wrap">
                    {RecentProjects.map(project => (
                        <div key={project.id} className="slick-item">
                            <div className="fet_pr-carousel-box">
                                <div className="fet_pr-carousel-box-media fl-wrap">
                                    <img src={project.thumbnail} className="respimg" alt={project.company} title={project.company} />
                                    <a href={project.thumbnail} className="fet_pr-carousel-box-media-zoom   image-popup"><i className="fal fa-search"></i></a>
                                </div>
                                <div className="fet_pr-carousel-box-text fl-wrap">
                                    <h3><a href="#">{project.company}</a></h3>
                                    <div className="fet_pr-carousel-cat">
                                        {
                                            project.tags.map(tag => (
                                                <a key={tag.id} href="javascript:;">{tag.title}</a>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="sp-cont sp-arr sp-cont-prev"><i className="fal fa-long-arrow-left"></i></div>
                <div className="sp-cont sp-arr sp-cont-next"><i className="fal fa-long-arrow-right"></i></div>
            </div>
            <div className="fet_pr-carousel-counter"></div>
        </section>
    )
}

export default HomeRecentProjects
