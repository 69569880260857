import React from 'react'
import synology from '../images/technologies/tech-synology-8403c192e3fbfd5fc9157fa6005f126d.png'
import cisco from '../images/technologies/tech-cisco-6afab7c941a2121f27e5a69ab46601a1.png'
import fortinet from '../images/technologies/fortinet.png'
import tech from '../images/technologies/tech-windowsserverclient-d6653bdec5086a2dff2aaa93a8fdd5ed.png'
import eset from '../images/technologies/eset.png'
import anydesk from '../images/technologies/anydesk.png'
import google from '../images/technologies/tech-googleworkspace-f57cb6efe1e92d4a634c38f81f01a577.png'
import aws from '../images/technologies/tech-aws-43bfc090c06f02711452ed165987524a.png'
import microsoft from '../images/technologies/microsoft.png'
import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import grafana from '../images/technologies/tech-grafana-8a1edfed75528afa4fd2f82c7c73c299.png'
import egn from '../images/technologies/egn.png'
import sav from '../images/technologies/acronis.png'

import tplink from '../images/technologies/tplink.png'
import linux from '../images/technologies/linux.png'
import checkpoint from '../images/technologies/checkpoint.png'
import paloalto from '../images/technologies/paloatlo.png'
import bitdefender from '../images/technologies/bitdefender.png'
import quantam from '../images/technologies/quantam.png'
import strata from '../images/technologies/strata.png'
import hyper from '../images/technologies/hyper.png'
import vbox from '../images/technologies/vbox.png'
import ansible from '../images/technologies/ansible.png'

import BiophiliaIcon from '../images/clients/biophilia-icon.png'

import TechnomedicsIcon from '../images/clients/technomedics-icon.png'

import AuthenticitiesIcon from '../images/clients/asl-icon.png'

import CppGlassIcon from '../images/clients/cppglass-icon.png'

import UnicornMetalicsIcon from '../images/clients/unicornmetalics-icon.png'



import BiophiliaLogo from '../images/clients/biophilia-logo.png'

import TechnomedicsLogo from '../images/clients/technomedics-logo.png'

import AuthenticitieslLogo from '../images/clients/asl-logo.png'

import CppGlasslLogo from '../images/clients/cppglass-logo.png'

import UnicornMetalicsLogo from '../images/clients/unicornmetalics-logo.png'




const reviews = [
    {
        id: 1,
        text: "They worked in a professional way and helped in giving the best outcome.",
        client: 'Technomedics (Pvt) Ltd',
        contact: 'AGM',
        image: TechnomedicsIcon,
        link: 'https://clutch.co/profile/pragicts#review-1727492'
    },
    {
        id: 2,
        text: "PragITS communicates effectively and they are easy to approach.",
        client: 'Authenticities (Pvt) Ltd',
        contact: 'Vice President & Director',
        image: AuthenticitiesIcon,
        link: 'https://clutch.co/profile/pragicts#review-1724895'
    },
    {
        id: 3,
        text: "They’ve become part of our team.",
        client: 'CPP Glass Pvt. Ltd',
        contact: 'Managing Director',
        image: CppGlassIcon,
        link: 'https://clutch.co/profile/pragicts#review-1717058'
    },
    {
        id: 4,
        text: "The team is very professional and they provide personalized services.",
        client: 'Unicorn Metalics Company (Pvt) Ltd',
        contact: 'Managing Director',
        image: UnicornMetalicsIcon,
        link: 'https://clutch.co/profile/pragicts#review-1717547'
    },
    {
        id: 5,
        text: "They worked in a professional way and helped in giving the best outcome.",
        client: 'Biophilia (Pvt) Ltd',
        contact: 'CEO',
        image: BiophiliaIcon,
        link: 'https://clutch.co/profile/pragicts#review-1715643'
    }
]



const HomeClientTestimonials = () => {
    return (
        <section  data-scrollax-parent="true" id="client">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Testimonials<span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Independent Direct Reviews</h3>
                    <h2>Clients & <span>Testimonials</span></h2>
                 
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="slider-carousel-wrap text-carousel-wrap fl-wrap">
                <div className="text-carousel-controls fl-wrap">
                    <div className="container">
                        <div className="sp-cont  sp-cont-prev"><i className="fal fa-long-arrow-left"></i></div>
                        <div className="sp-cont   sp-cont-next"><i className="fal fa-long-arrow-right"></i></div>
                    </div>
                </div>
                <div className="text-carousel cur_carousel-slider-container fl-wrap">
                    {reviews.map(review => (
                        <div key={review.id} className="slick-item">
                            <div className="text-carousel-item">
                                <div className="popup-avatar"><img src={review.image} alt={review.client} title={review.client} /></div>
                                <div className="listing-rating card-popup-rainingvis" data-starrating2="5"> </div>
                                <div className="review-owner fl-wrap">{review.contact}  - <span>{review.client}</span></div>
                                <p> {review.text}</p>
                                <a href={review.link} target="_blank" className="testim-link">Via Clutch</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
         
           
        </section>
    )
}

export default HomeClientTestimonials
