import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeWorkingProcess = () => {

    return (
        <section data-scrollax-parent="true" id="client">
        <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >Solutions by PragICTS <span>//</span></div>
        <div className="container">
            <div className="section-title fl-wrap">
                <h3>Software solutions by PragICTS</h3>
                <h2>Software <span> Solutions</span></h2>
              
            </div>
            <div className="custom-inner-holder  mar-bottom">
                <div className="custom-inner">
                    <div className="row">
                        <div className="col-md-9">
                        <div className="custom-inner-content fl-wrap">
                                <h4>AGC</h4>
                                <p>PAY PLATFORM SECURITY IS ENABLED THROUGH A MULTI-LAYERED DECOUPLED AND ABSTRACTED SECURITY ARCHITECTURE WITH ENDPOINT ENCRYPTION THROUGH SHA - 2 AND 2048-BIT SSL.</p>
                                <a href="https://agc.lk/" className="cus-inner-head-link color-bg">Details + </a>
                            </div>
                     
                        </div>
                       
                        <div className="col-md-2">
                            <StaticImage src='../images/clients/asian-conecpt-logo-bb684a0c4b82a406a258d890e7c96321.png'  alt="Cognizant by PragICTS" title="Cognizant by PragICTS" ></StaticImage>
                        </div>
                    </div>
                </div>
                <div className="custom-inner">
                <div className="row">
                        <div className="col-md-9">
                        <div className="custom-inner-content fl-wrap">
                                <h4>AUTHENTICITIES</h4>
                                <p>AUTHENTICITIES SRI LANKA MIGRATES ALL THEIR ONLINE ASSETS(WEBSITE, ONLINE INVOICING AND PLAYMENT PLATFORM) FROM GODADDY TO AWS THROUGH PRAGICTS.</p>
                                <a href="https://authenticitiessrilanka.com/#Authenticities" className="cus-inner-head-link color-bg">Details + </a>
                            </div>
                  
                        </div>
                       
                        <div className="col-md-2">
                            <StaticImage src='../images/clients/authenticities-logo.jpg'  alt="Cognizant by PragICTS" title="Cognizant by PragICTS" ></StaticImage>
                        </div>
                    </div>
                </div>
                <div className="custom-inner">
                <div className="row">
                        <div className="col-md-9">
                        <div className="custom-inner-content fl-wrap">
                                <h4>CPP GLASS</h4>
                                <p>CPP COMMISSIONS THEIR NEW ONLINE PRESENCE STRATEGY WITH THE LAUNCH OF THEIR NEW WEBSITE DESIGNED AND ENGINEERED BY PRAGICTS.</p>
                                <a href="https://cppglass.com/" className="cus-inner-head-link color-bg">Details + </a>
                            </div>
             
                        </div>
                       
                        <div className="col-md-2">
                            <StaticImage src='../images/clients/cpp.png'  alt="Cognizant by PragICTS" title="Cognizant by PragICTS" ></StaticImage>
                        </div>
                    </div>
                </div>
                <div className="custom-inner">
                <div className="row">
                        <div className="col-md-9">
                        <div className="custom-inner-content fl-wrap">
                                <h4>UNICORN METALICS</h4>
                                <p>UNICORN METALICS COMMISSIONS THEIR NEW ONLINE PRESENCE STRATEGY WITH THE LAUNCH OF THEIR NEW WEBSITE DESIGNED AND ENGINEERED BY PRAGICTS.</p>
                                <a href="#" className="cus-inner-head-link color-bg">Details + </a>
                            </div>
                        
                        </div>
                       
                        <div className="col-md-2">
                            <StaticImage src='../images/clients/unicornmetalics.png'  alt="Cognizant by PragICTS" title="Cognizant by PragICTS" ></StaticImage>
                        </div>
                    </div>
                </div>
                <div className="custom-inner">
                <div className="row">
                        <div className="col-md-9">
                        <div className="custom-inner-content fl-wrap">
                                <h4>TOOLSHUB</h4>
                                <p>UNICORN METALLICS COMMISIONS SRI LANKA'S PREMIER ECOMMERCE PLATFORM FOR TOOLS ENGINEERED BY PRAGICTS.</p>
                                <a href="https://toolshub.lk/" className="cus-inner-head-link color-bg">Details + </a>
                            </div>
              
                        </div>
                       
                        <div className="col-md-2">
                            <StaticImage src='../images/clients/toolshub.png'  alt="Cognizant by PragICTS" title="Cognizant by PragICTS" ></StaticImage>
                        </div>
                    </div>
                </div>
                <div className="custom-inner">
                <div className="row">
                        <div className="col-md-9">
                        <div className="custom-inner-content fl-wrap">
                                <h4>PRAGUNA GLOBAL</h4>
                                <p>PRAGUNA GLOBAL COMMISSIONS THEIR NEW ONLINE PRESENCE STRATEGY WITH THE LAUNCH OF ITS NEW WEBSITE DESIGNED AND ENGINEERED BY PRAGICTS.</p>
                                <a href="http://praguna-global.com/" className="cus-inner-head-link color-bg">Details + </a>
                            </div>
                        
                        </div>
                       
                        <div className="col-md-2">
                            <StaticImage src='../images/clients/praguna.png'  alt="Cognizant by PragICTS" title="Cognizant by PragICTS" ></StaticImage>
                        </div>
                    </div>
                </div>
                <div className="custom-inner">
                <div className="row">
                        <div className="col-md-9">
                        <div className="custom-inner-content fl-wrap">
                                <h4>RIGOR CONSULTING</h4>
                                <p>RIGOR CONSULTING COMMISSIONS THEIR NEW ONLINE PRESENCE STRATEGY WITH THE LAUNCH OF ITS NEW WEBSITE DESIGNED AND ENGINEERED BY PRAGICTS</p>
                                <a href="https://ecommerce.pragicts.com" className="cus-inner-head-link color-bg">Details + </a>
                            </div>
               
                        </div>
                       
                        <div className="col-md-2">
                            <StaticImage src='../images/clients/rigor.png'  alt="Cognizant by PragICTS" title="Cognizant by PragICTS" ></StaticImage>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sec-lines"></div>
    </section>
    )
}

export default HomeWorkingProcess
